











































































import { mapGetters, mapState } from 'vuex';
import router from '@/router';
import GuidanceMixin from '@/mixins/guidance/GuidanceMixin';

export default {
  name: 'CopyStakeGuidanceView',
  mixins: [GuidanceMixin],
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('FeatureFlags', [
      'isEnabledFeatureFlag',
      'featuresLoading'
    ]),
    isEnabledCopyStake(): boolean {
      return this.isEnabledFeatureFlag('live_streamer');
    }
  },
  watch: {
    featuresLoading: {
      handler(value: boolean): void {
        if (value || this.isEnabledCopyStake || this.isSuperAdmin) return;

        router.push('/');
      },
      immediate: true
    }
  }
};
