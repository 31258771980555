import { TCopyStakeGuidanceListResponse, TGuidance } from '@/api/schema';
import { getGuidanceList } from '@/api/CopyStake';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';

export interface helpMaterialsItem {
  text: string;
  icon?: string;
  component?: any;
}

const CogIcon = (): any =>
  import('@/assets/images/icons/cog-4-line.svg?inline');

export default {
  data: (): any => ({
    isLoading: false as boolean,
    guidanceList: [] as TGuidance[]
  }),
  computed: {
    helpMaterialsList(): helpMaterialsItem[] {
      return [
        {
          text: 'Configure the feature',
          component: CogIcon
        },
        {
          text: 'Promote the feature among casino audience',
          icon: 'mdi-account-group-outline'
        },
        {
          text: 'Provide support to users',
          icon: 'mdi-email-outline'
        }
      ];
    }
  },
  created(): void {
    this.getGuidanceList();
  },
  methods: {
    async getGuidanceList(): Promise<void> {
      this.isLoading = true;

      await getGuidanceList()
        .then((data: TCopyStakeGuidanceListResponse) => {
          this.guidanceList = data;
        })
        .catch((err: AxiosError) => {
          this.guidanceList = [];
          errorToastMessage(err);
        })
        .finally((): void => {
          this.isLoading = false;
        });
    },
    openLink(guidance: TGuidance): void {
      window.open(guidance.url, '_blank');
    }
  }
};
